import { PurposesOfActivityTranslate, PurposesOfActivityType } from '@dltru/dfa-models'

export const Options = [
    {
        label: PurposesOfActivityTranslate.INCREASING_REVENUE,
        value: PurposesOfActivityType.INCREASING_REVENUE,
        order: 1,
    },
    {
        label: PurposesOfActivityTranslate.LEADING_POSITIONS,
        value: PurposesOfActivityType.LEADING_POSITIONS,
        order: 2,
    },
    {
        label: PurposesOfActivityTranslate.INCREASING_ASSETS_VALUE,
        value: PurposesOfActivityType.INCREASING_ASSETS_VALUE,
        order: 3,
    },
    {
        label: PurposesOfActivityTranslate.COMMUNITY_PROJECTS,
        value: PurposesOfActivityType.COMMUNITY_PROJECTS,
        order: 4,
    },
    {
        label: PurposesOfActivityTranslate.JOB_CREATION,
        value: PurposesOfActivityType.JOB_CREATION,
        order: 5,
    },
]
