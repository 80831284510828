import { PurposesOfActivityType } from '@dltru/dfa-models'
import { FC } from 'react'

import { WrappedInput, WrappedSelect } from '../../components/Form'
import { FinancialActivity } from './FinancialActivity'
import { relationshipOfCompanyOptions } from './resources'
import { FinancialOperationsStepProfileType } from '@dltru/dfa-models/src/mappers/roleForms/common'

interface IComponentProps {
    uneditable: boolean
    financialActivityInitialValues: {
        other?: string
        financial_activity_purpose: PurposesOfActivityType[]
    }
    profileType: FinancialOperationsStepProfileType
}
export const FinanceAndPlannedForm: FC<IComponentProps> = ({
    uneditable,
    financialActivityInitialValues,
    profileType
}) => {
    return (
        <>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Сведения о планируемых операциях"
                    name="planned_operations"
                    uneditable={uneditable}
                    placeholder="Операции с ЦФА"
                    initialValue="Операции с ЦФА"
                    disabled
                    required
                />
            </div>
            <div className="formRow">
                <WrappedSelect
                    label="Предполагаемый характер деловых отношений с Компанией"
                    uneditable={uneditable}
                    className="formComponentItem"
                    name="business_relations_type"
                    options={relationshipOfCompanyOptions}
                />
            </div>
            {/*<div className="formRow">
                <WrappedSelect
                    label="Сведения о целях финансово-хозяйственной деятельности"
                    uneditable={uneditable}
                    className="formComponentItem"
                    name="financial_activity_purpose"
                    options={purposesOfActivityOptions}
                />
            </div>*/}
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Сведения об источниках происхождения денежных средств"
                    name="sources_funds"
                    placeholder="Прибыль от коммерческой деятельности или иное (укажите свой вариант)"
                    uneditable={uneditable}
                    required
                    maxLength={100}
                />
            </div>
            <div style={{ marginTop: 16 }}>
                <FinancialActivity
                    uneditable={uneditable}
                    initValues={financialActivityInitialValues}
                    profileType={profileType}
                />
            </div>
        </>
    )
}
