// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".formLabel_eeuHb {\n  width: 240px;\n  padding: 0 30px 24px 0;\n  white-space: pre-line;\n  color: rgba(0, 0, 0, 0.85);\n  font-size: 14px;\n  text-align: right;\n  color: #40434d;\n}\n.showOnly_MWDem {\n  color: #868991;\n}\n.group__Cfq9 {\n  display: flex !important;\n  flex-direction: column;\n}\n.otherField_jOszw {\n  margin-top: -16px !important;\n  margin-bottom: 16px !important;\n}\n.otherInput_kL_5m {\n  width: 540px;\n  margin-left: 20px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/roleForms/FinancialActivity/FinancialActivity.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,sBAAA;EACA,qBAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEA;EACI,cAAA;AAAJ;AAGA;EACI,wBAAA;EACA,sBAAA;AADJ;AAIA;EACI,4BAAA;EACA,8BAAA;AAFJ;AAKA;EACI,YAAA;EACA,iBAAA;AAHJ","sourcesContent":[".formLabel {\n    width: 240px;\n    padding: 0 30px 24px 0;\n    white-space: pre-line;\n    color: rgba(0, 0, 0, 0.85);\n    font-size: 14px;\n    text-align: right;\n    color: #40434d;\n}\n\n.showOnly {\n    color: #868991;\n}\n\n.group {\n    display: flex !important;\n    flex-direction: column;\n}\n\n.otherField {\n    margin-top: -16px !important;\n    margin-bottom: 16px !important;\n}\n\n.otherInput {\n    width: 540px;\n    margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formLabel": "formLabel_eeuHb",
	"showOnly": "showOnly_MWDem",
	"group": "group__Cfq9",
	"otherField": "otherField_jOszw",
	"otherInput": "otherInput_kL_5m"
};
export default ___CSS_LOADER_EXPORT___;
