import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, CheckboxChangeEvent } from '../../../Checkbox'
import { Space } from '../../../Space'
import cn from 'classnames'
import styles from './BuisnessControl.m.less'
import { Labels } from './consts'
import { Form } from '../../../components/Form'

interface IBuisnessControlProps {
    uneditable: boolean
    owns_quarter?: boolean
    legal_entity_control?: boolean
    fieldName?: string
    checkOwnsQuarter?: (value: boolean) => void
    checkLegalEntityControl?: (value: boolean) => void
}

export const BuisnessControl: FC<IBuisnessControlProps> = ({
    uneditable,
    owns_quarter,
    legal_entity_control,
    checkOwnsQuarter,
    checkLegalEntityControl,
    fieldName,
}) => {
    const [isLegalEntityControl, setIsLegalEntityControl] = useState(legal_entity_control)
    const [isOwnsQuarter, setIsOwnsQuarter] = useState(owns_quarter)

    const checkOwnsQuarterHandler = useCallback((e: CheckboxChangeEvent) => {
        setIsOwnsQuarter(e.target.checked)
        checkOwnsQuarter && checkOwnsQuarter(e.target.checked)
    }, [])

    const checkLegalEntityControlHandler = useCallback((e: CheckboxChangeEvent) => {
        setIsLegalEntityControl(e.target.checked)
        checkLegalEntityControl && checkLegalEntityControl(e.target.checked)
    }, [])

    useEffect(() => {
        setIsOwnsQuarter(owns_quarter)
    }, [owns_quarter])

    useEffect(() => {
        setIsLegalEntityControl(legal_entity_control)
    }, [legal_entity_control])

    const childEls = useMemo(() => {
        return uneditable ? (
            <>
                {isOwnsQuarter && <span>{Labels.owns_quarter}</span>}
                {isLegalEntityControl && <span>{Labels.legal_entity_control}</span>}
            </>
        ) : (
            <>
                <Form.Item
                    name={fieldName !== undefined ? [fieldName, 'owns_quarter'] : 'owns_quarter'}
                    valuePropName="checked"
                    noStyle
                >
                    <Checkbox checked={isOwnsQuarter} onChange={checkOwnsQuarterHandler}>
                        {Labels.owns_quarter}
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name={
                        fieldName !== undefined
                            ? [fieldName, 'legal_entity_control']
                            : 'legal_entity_control'
                    }
                    valuePropName="checked"
                    noStyle
                >
                    <Checkbox
                        checked={isLegalEntityControl}
                        onChange={checkLegalEntityControlHandler}
                    >
                        {Labels.legal_entity_control}
                    </Checkbox>
                </Form.Item>
            </>
        )
    }, [
        uneditable,
        checkOwnsQuarterHandler,
        isOwnsQuarter,
        checkLegalEntityControlHandler,
        isLegalEntityControl,
        fieldName,
    ])

    return (
        <div className="formRow">
            <div className={cn(styles.formLabel, { [styles.showOnly]: uneditable })}>
                Влияние на деятельность юридического лица:
            </div>
            <Space direction="vertical" style={{ width: 'auto' }}>
                {childEls}
            </Space>
        </div>
    )
}
