import { PurposesOfActivityType } from '@dltru/dfa-models'
import { Options } from './consts'

export const getViewOptionsElements = (initValues: PurposesOfActivityType[]) =>
    initValues
        .map((val) => {
            const finded = Options.find((op) => op.value === val)
            if (finded) {
                return finded
            }

            return { order: 0, label: '' }
        })
        .sort((a, b) => a.order - b.order)
        .map((item) => <span>{item.label}</span>)
