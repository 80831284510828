import { FC, useCallback, useState } from 'react'
import cn from 'classnames'
import { Checkbox, CheckboxChangeEvent, CheckboxGroup } from '../../../Checkbox'
import { FormItem, WrappedInput } from '../../../components/Form'
import { Options } from './consts'

import styles from './FinancialActivity.m.less'
import { ProfileType, PurposesOfActivityTranslate, PurposesOfActivityType } from '@dltru/dfa-models'
import { getViewOptionsElements } from './utils'
import { FinancialOperationsStepProfileType } from '@dltru/dfa-models/src/mappers/roleForms/common'

interface IFinancialActivityProps {
    uneditable: boolean
    initValues: { other?: string; financial_activity_purpose: PurposesOfActivityType[] }
    profileType: FinancialOperationsStepProfileType
}

export const FinancialActivity: FC<IFinancialActivityProps> = ({ uneditable, initValues, profileType }) => {
    const [isOther, setIsOther] = useState(false)

    const onCheckOtherHandler = useCallback(
        (e: CheckboxChangeEvent) => {
            setIsOther(e.target.checked)
        },
        [isOther],
    )

    return (
        <div className="formRow">
            <div className={cn(styles.formLabel, { [styles.showOnly]: uneditable })}>
                Сведения о финансово-хозяйственной деятельности:
            </div>
            {uneditable && (
                <div className={styles.group}>
                    {getViewOptionsElements(initValues.financial_activity_purpose)}
                    {initValues.other && <span>{initValues.other}</span>}
                </div>
            )}
            {!uneditable && (
                <div className={styles.group}>
                    <FormItem name="financial_activity_purpose">
                        <CheckboxGroup
                            options={Options}
                            className={styles.group}
                            disabled={uneditable}
                        />
                    </FormItem>
                    {profileType === ProfileType.LEGL && 
                        <>
                          <Checkbox
                            checked={isOther}
                            onChange={onCheckOtherHandler}
                            className={styles.otherField}
                          >
                          {PurposesOfActivityTranslate.OTHER}
                          </Checkbox>
                          {isOther && (
                              <div className={styles.otherInput}>
                                  <WrappedInput multiline name="other" />
                              </div>
                          )}
                        </>
                    }
                </div>
            )}
        </div>
    )
}
