// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".formLabel_R9X0l {\n  width: 240px;\n  padding: 0 30px 24px 0;\n  white-space: pre-line;\n  color: rgba(0, 0, 0, 0.85);\n  font-size: 14px;\n  text-align: right;\n  color: #40434d;\n}\n.showOnly_I2szw {\n  color: #868991;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/roleForms/BeneficialOwner/BuisnessControl.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,sBAAA;EACA,qBAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEA;EACI,cAAA;AAAJ","sourcesContent":[".formLabel {\n    width: 240px;\n    padding: 0 30px 24px 0;\n    white-space: pre-line;\n    color: rgba(0, 0, 0, 0.85);\n    font-size: 14px;\n    text-align: right;\n    color: #40434d;\n}\n\n.showOnly {\n    color: #868991;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formLabel": "formLabel_R9X0l",
	"showOnly": "showOnly_I2szw"
};
export default ___CSS_LOADER_EXPORT___;
